import React, { useEffect, useState } from 'react'
import Img from 'gatsby-image'
import { Link } from 'gatsby'

import { getFluidImage } from '../utils/images'
import { chunkArray, getComponentClass, getComponentId, isInternalUrl } from '../utils/helpers'

const BoxReference = ({ settings, pageContext }) => {
  const { boxElements } = settings
  const { langSlug } = pageContext
  const handleWindowSizeChange = () => {
    if (typeof window !== 'undefined') {
      setWidth(window.innerWidth)
    }
  }
  useEffect(() => {
    if (typeof window !== 'undefined') {
      setWidth(window.innerWidth)
      window.addEventListener('resize', handleWindowSizeChange)
      return () => {
        window.removeEventListener('resize', handleWindowSizeChange)
      }
    }
  }, [])
  const [width, setWidth] = useState()
  const isMobile = width <= 768

  const rows = chunkArray(boxElements, isMobile ? 2 : 3)

  const componentId = getComponentId(settings)

  const renderBoxImage = image => {
    return (
      <Img
        fluid={getFluidImage({
          cloudinary: image?.public_id,
          ar: 1.55,
          width: '20px',
          maxWidth: 363,
          widths: [320, 363]
        })}
        alt={image?.meta?.description || 'box reference'}
        title={image?.meta?.description}
        className='hover-zoom'
        loading='lazy'
        placeholderStyle={{ backgroundColor: 'lightgray' }}
      />
    )
  }

  const renderBoxContent = text => {
    return (
      <div
        className='box-item-content'
        dangerouslySetInnerHTML={{ __html: text }}
      />
    )
  }

  return (
    <div
      id={componentId}
      className={`box-reference bg-white ${getComponentClass(settings)}`}
    >
      {rows.map((row, rowIndex) => (
        <div className='row' key={rowIndex}>
          {row.map((element, index) => {
            const url = element.value?.url?.value
            let text = element.value?.text?.value
            // replace <h4> tags with <h3>
            text = text.replace('<h4>', '<h3>')
            text = text.replace('</h4>', '</h3>')
            const image = element.value?.image?.value?.[0]
            return (
              <div className='col-sm-4 col-xs-6 box-item' key={index}>
                <div className='mobile-content-wrapper'>
                  {isInternalUrl(url) ? (
                    <Link to={`/${langSlug}${url}`}>
                      {renderBoxImage(image)}
                      {renderBoxContent(text)}
                    </Link>
                  ) : (
                    <a href={url} target='_blank' rel='noreferrer'>
                      {renderBoxImage(image)}
                      {renderBoxContent(text)}
                    </a>
                  )}
                </div>
              </div>
            )
          })}
        </div>
      ))}
    </div>
  )
}

export default BoxReference
