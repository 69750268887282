import React, { useEffect, useState } from 'react'

function ReadMoreWrapper (props) {
  const [more, setMore] = useState(false)
  const [width, setWidth] = useState()

  const handleWindowSizeChange = () => {
    if (typeof window !== 'undefined') {
      setWidth(window.innerWidth)
    }
  }
  useEffect(() => {
    if (typeof window !== 'undefined') {
      setWidth(window.innerWidth)
      window.addEventListener('resize', handleWindowSizeChange)
      return () => {
        window.removeEventListener('resize', handleWindowSizeChange)
      }
    }
  }, [])

  const isMobile = width <= 768

  const resetMore = () => {
    setMore(!more)
  }

  const moreStyle = { position: 'absolute', bottom: 14, left: 20 }
  const lessStyle = { position: 'absolute', bottom: 19, right: 20 }
  return (
    <div
      style={{ maxHeight: more || !isMobile ? '100%' : 92, overflow: 'hidden' }}
    >
      <div dangerouslySetInnerHTML={{ __html: props.children }} />
      {isMobile && (
        <b
          className='content-element'
          onClick={resetMore}
          onKeyPress={resetMore}
          style={more ? moreStyle : lessStyle}
          // aria-hidden='true'
          role='button'
          tabIndex='0'
        >
          <u>{more ? 'Weniger anzeigen' : '...Weiterlesen'}</u>
        </b>
      )}
    </div>
  )
}

export default ReadMoreWrapper
