import React from 'react'
import { Link } from 'gatsby'

import {
  getComponentClass,
  getComponentId,
  splitArrayEvenly,
  splitDestinationsIntoGroups
} from '../utils/helpers'

const DestinationsList = ({ settings, pageContext }) => {
  const { destinations } = settings
  const { langSlug } = pageContext

  const componentId = getComponentId(settings)

  const destinationGroups = splitDestinationsIntoGroups(destinations)

  const renderDestinations = (destinations, title) => {
    const columns = splitArrayEvenly(destinations, 3)

    return (
      <div className='mobile-content-wrapper'>
        <h2>{title}</h2>
        <div className='row'>
          {columns.map((col, index) => (
            <div className='col-sm-4' key={index}>
              {col.map((destination, index) => (
                <Link to={langSlug + destination.slug?.value} key={index}>
                  <strong>{langSlug + destination.name?.value}</strong>reisen
                </Link>
              ))}
            </div>
          ))}
        </div>
      </div>
    )
  }

  return (
    <div
      id={componentId}
      className={`destinations-list destinations-list-list ${getComponentClass(
        settings
      )}`}
    >
      {renderDestinations(destinationGroups.destinations, 'Reiseziele')}
      {renderDestinations(destinationGroups.categories, 'Reisearten')}
    </div>
  )
}

export default DestinationsList
